<template>
  <div class="hrdcloud-list-wrapper">
    <ul class="hrdcloud-list" data-grid="4">
      <li v-for="(item, idx) in items" :key="idx" class="hrdcloud-item">
        <article class="hrdcloud-card">
          <div class="hrdcloud-image">
            <div class="image">
              <router-link :to="{name:'HrdScorm'}" class="image-link">
                <img :src="item.thumnailSrc" alt="임시이미지">
              </router-link>
            </div>
            <div class="badges">
              <div v-if="item.seriesYn === 'y'" class="badge-active">
                <span class="text">시리즈</span>
              </div>
              <div v-else-if="item.seriesYn !== 'y' && item.completeYn === 'y'" class="badge-inactive">
                <span class="text">완료</span>
                <i class="icon-check"></i>
              </div>
            </div>
          </div>
          <div class="hrdcloud-content">
            <div class="content-category">
              <span class="text">{{item.hrdCategory}}</span>
            </div>
            <div class="content-title">
              <h5 class="title"><a href="javascript:" class="title-link">{{item.hrdTitle}}</a></h5>
            </div>
            <div class="content-info">
              <div class="info">
                <i class="icon-views"></i>
                <span class="text">{{item.viewCnt}}</span>
              </div>
              <div class="info">
                <i class="icon-like"></i>
                <span class="text">{{item.favoriteCnt}}</span>
              </div>
            </div>
            <div class="content-meta">
              <span class="text">{{item.regDttm}}</span>
              <span class="text">{{item.playTime}}</span>
            </div>
            <div class="content-interest">
              <button class="kb-btn-interest" data-content="담기">
                <i class="icon-interest"></i>
              </button>
            </div>
          </div>
          <div class="hrdcloud-actions">
            <button class="kb-btn kb-btn-outline rounded-lg"><span class="text">데모시작</span></button>
            <button class="kb-btn kb-btn-outline rounded-lg"><span class="text">실습시작</span></button>
          </div>
        </article>
      </li>
    </ul>
    <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
  </div>
</template>

<script>
import {ref, watch} from 'vue';
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {getItems, getPaging, initPaging, lengthCheck} from '@/assets/js/util';
import {ACT_GET_CLOUD_EASY_LIST} from '@/store/modules/prep/prep';
import CommonPaginationFront from '@/components/CommonPaginationFront';

export default {
  name: "MyCloudEasy",
  components: {CommonPaginationFront},
  setup() {
    const isActive = ref(false);
    const activeMenu = ref('연수');

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const items = ref([]);
    const paging = ref(initPaging(route));

    const getMyKeepCloudEasyList = () => {
      store.dispatch(`prep/${ACT_GET_CLOUD_EASY_LIST}`, {
      }).then(res => {
        if(lengthCheck(res)){
          items.value = getItems(res);
          paging.value = getPaging(res)
        }else{
          items.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      })
    }
    getMyKeepCloudEasyList();

    const pagingFunc = (pageNo, pageSize) => {
      const query = {type: route.query.type, pageNo: pageNo};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      router.push({query: query});
    };

    watch(() => route.query, () => {
      if (route.query.pageNo) {
        paging.value.pageNo = route.query.pageNo;
      } else {
        paging.value.pageNo = 1;
      }
      if (route.query.pageSize) {
        paging.value.pageSize = route.query.pageSize;
      }
      getMyKeepCloudEasyList();
    });

    watch(() => activeMenu.value, () => {
       getMyKeepCloudEasyList();
    })

    return {
      isActive,
      activeMenu,
      items,
      paging,

      pagingFunc,
    }
  }
}
</script>