<template>
  <div :class="{ 'course-list-container' : activeMenu === '연수', 'hrdcloud-list-container' : activeMenu === 'HRD 클라우드', 'hrdcloud-list-wrapper' : activeMenu === '클라우드 Easy' }" data-view="row">
    <MyDropdownList v-model="activeMenu" />
    <MyTrain v-if="activeMenu === '연수'"/>
    <MyHrdCloud v-else-if="activeMenu === 'HRD 클라우드'"/>
    <MyCloudEasy v-else />
  </div>
</template>

<script>
import {ref, watch} from 'vue';
import MyDropdownList from '@/components/my/MyDropdownComponent';
import {useRoute} from "vue-router";
import MyHrdCloud from "@/components/my/MyHrdCloud";
import MyTrain from "@/components/my/MyTrain";
import MyCloudEasy from "@/components/my/MyCloudEasy";

export default {
  name: 'MyKeep',
  props:{
    modelValue: String,
  },
  emits: ['update:modelValue'],
  components: {MyCloudEasy, MyTrain, MyHrdCloud, MyDropdownList},
  setup(props, {emit}) {
    const isActive = ref(false);
    const activeMenu = ref('연수');

    const route = useRoute();

    watch(() => route.query, () => {
      emit('update:modelValue', activeMenu.value);
    });

    watch(() => activeMenu.value, () => {
      emit('update:modelValue', activeMenu.value);
    })

    return {
      isActive,
      activeMenu,
    }
  }
};
</script>
