<template>
  <!-- list-top -->
  <div class="list-top">
    <div class="top-column">
      <!-- list-top > list-sort -->
      <div class="list-sort">
        <div class="sort">
          <!-- kb-form-dropdown -->
          <div class="kb-form-dropdown" :class="{ 'is-active' : isActive }" @click="isActive = !isActive">
            <button class="kb-form-dropdown-btn"><strong class="text">{{ modelValue }}</strong><i class="icon-arrow"></i></button>
            <div class="kb-form-dropdown-options">
              <div class="dropdown-option">
                <ul class="dropdown-option-list">
                  <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link" @click="selectMenu('연수')"><strong class="dropdown-option-text">연수</strong></a></li>
                  <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link" @click="selectMenu('HRD 클라우드')"><strong class="dropdown-option-text">HRD 클라우드</strong></a></li>
                  <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link" @click="selectMenu('클라우드 Easy')"><strong class="dropdown-option-text">클라우드 Easy</strong></a></li>
                </ul>
              </div>
            </div>
          </div>
          <!-- //kb-form-dropdown -->
        </div>
      </div>
    </div>
  </div>
  <!-- //list-top -->
</template>

<script>
import {ref} from 'vue';

export default {
  name: 'MyDropdownList',
  props: {
    modelValue: String
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const isActive = ref(false);
    const activeMenu = ref('');

    const selectMenu = (menu) => {
      activeMenu.value = menu;
      emit('update:modelValue', activeMenu.value);
    }

    return {
      isActive,
      activeMenu,
      selectMenu
    }
  }
};
</script>